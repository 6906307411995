import { createSlice } from '@reduxjs/toolkit'
import { jwtDecode as jwt_decode } from 'jwt-decode'
import { fetchUserPermissions } from './permissionsAction'
const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: {
    permissions:
      JSON.parse(localStorage.getItem('userData'))?.permissions || null
  },
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserPermissions.fulfilled, (state, action) => {
      state.permissions = action.payload?.permissions
    })
  }
})
export const { setPermissions } = permissionsSlice.actions
const permissionsReducer = permissionsSlice.reducer
export default permissionsReducer
// Helper function to check complex permissions
export const checkComplexPermission = (permissions, rule) => {
  if (typeof rule === 'string') {
    return permissions?.[rule] === true
  }
  if (Array.isArray(rule)) {
    return rule.some((subRule) => checkComplexPermission(permissions, subRule))
  }
  if (typeof rule === 'object') {
    return Object.entries(rule).every(([key, value]) => {
      if (key === 'OR') {
        return value.some((subRule) =>
          checkComplexPermission(permissions, subRule)
        )
      }
      if (key === 'AND') {
        return value.every((subRule) =>
          checkComplexPermission(permissions, subRule)
        )
      }
      return permissions?.[key] === value
    })
  }
  return false
}
