import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

// ** components
import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'
import { updateUser } from '../authentication/authSlice'
export const fetchUserPermissions = createAsyncThunk(
  'providerPermissions/getProviderPermissions',
  async (payload, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.getProviderProfile()
      // const oldProfile = JSON.parse(localStorage.getItem('userData'))
      // const updatedProfile = response?.data
      // let newProfile = {}
      // for (const key in oldProfile) {
      //   if (updatedProfile.hasOwnProperty(key)) {
      //     newProfile[key] = updatedProfile[key]
      //   }
      // }
      // newProfile = {
      //   ...oldProfile,
      //   ...newProfile
      // }

      // localStorage.setItem('userData', JSON.stringify(newProfile))
      // dispatch(updateUser())
      return response?.data
    } catch (err) {
      return rejectWithValue(err?.response?.data)
    }
  }
)
