import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'
export const getProviderPayersAction = createAsyncThunk(
  'getProviderPayers/getProviderPayersAction',
  async ({ offset, limit, search }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getProviderPayers({
        offset,
        limit,

        search
      })

      return response.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Provider Payers'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const getPayersAction = createAsyncThunk(
  'getPayers/getPayersAction',
  async (params, { rejectWithValue }) => {
    try {
      const response = await useJwt.getPayers()

      return response.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Payers'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const addProviderPayerAction = createAsyncThunk(
  'addProviderPayer/addProviderPayerAction',
  async ({ data, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.addProviderPayer(data)
      dispatch(getProviderPayersAction({ offset: 0, limit: 10 }))
      if (callback) {
        callback()
      }
      return response.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Adding Provider Payer'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const deleteProviderPayerAction = createAsyncThunk(
  'deleteProviderPayer/deleteProviderPayerAction',
  async (id, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.deleteProviderPayer(id)
      dispatch(getProviderPayersAction({ offset: 0, limit: 10 }))
      return response.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Deleting Provider Payer'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
// ** Handling Pagination
export const handlePageChange = createAsyncThunk(
  'payer/handlePageChange',
  async ({ offset, limit }, { dispatch }) => {
    dispatch(
      getAllInsuranceClaimsAction({
        offset,
        limit
      })
    )
  }
)

// ** Handling Limit
export const handleLimitChange = createAsyncThunk(
  'payer/handleLimitChange',
  async ({ oldLimit, newLimit, search }, { dispatch }) => {
    if (newLimit !== oldLimit) {
      dispatch(
        getAllInsuranceClaimsAction({
          offset: 0,
          limit: newLimit,

          search
        })
      )
    }
  }
)
