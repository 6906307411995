import { createSlice } from '@reduxjs/toolkit'

import {
  createInsuranceClaimAction,
  getAllUnbilledInvoicesAction,
  getAllInsuranceClaimsAction,
  getCreatedClaimDataAction,
  submitInsuranceClaimAction
} from './insuranceAction'

const InsuranceReducer = createSlice({
  name: 'insurance',
  initialState: {
    invoicesPending: false,
    createInsuranceClaimPending: false,
    getAllInsuranceClaimsPending: false,
    getCreatedClaimDataPending: false,
    submitInsuranceClaimPending: false,
    getAllUnbilledInvoices: {
      data: {},
      offset: 0,
      limit: 10,
      count: 0
    },
    getAllInsuranceClaims: {
      result: [],
      offset: 0,
      limit: 10,
      count: 0
    },
    getCreatedClaimData: {
      client: null,
      provider: null,
      payer: null,
      claim: null,
      configurations: null,
      billingInfo: null
    },
    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ** Get all activities
      .addCase(getAllUnbilledInvoicesAction.pending, (state) => {
        state.invoicesPending = true
      })
      .addCase(getAllUnbilledInvoicesAction.fulfilled, (state, action) => {
        state.invoicesPending = false
        state.getAllUnbilledInvoices.data = action.payload
      })
      .addCase(getAllUnbilledInvoicesAction.rejected, (state, action) => {
        state.invoicesPending = false
        state.getAllUnbilledInvoices = {
          data: [],
          offset: 0,
          limit: 10,
          total: 0
        }
        state.error = action.payload
      })
      .addCase(createInsuranceClaimAction.pending, (state) => {
        state.createInsuranceClaimPending = true
      })
      .addCase(createInsuranceClaimAction.fulfilled, (state, action) => {
        state.createInsuranceClaimPending = false
      })
      .addCase(createInsuranceClaimAction.rejected, (state, action) => {
        state.createInsuranceClaimPending = false
      })
      .addCase(getAllInsuranceClaimsAction.pending, (state) => {
        state.getAllInsuranceClaimsPending = true
      })
      .addCase(getAllInsuranceClaimsAction.fulfilled, (state, action) => {
        state.getAllInsuranceClaimsPending = false
        state.getAllInsuranceClaims = action.payload
      })
      .addCase(getAllInsuranceClaimsAction.rejected, (state, action) => {
        state.getAllInsuranceClaimsPending = false
      })
      .addCase(getCreatedClaimDataAction.pending, (state) => {
        state.getCreatedClaimDataPending = true
      })
      .addCase(getCreatedClaimDataAction.fulfilled, (state, action) => {
        state.getCreatedClaimDataPending = false
        state.getCreatedClaimData = action.payload
      })
      .addCase(getCreatedClaimDataAction.rejected, (state, action) => {
        state.getCreatedClaimDataPending = false
      })
      .addCase(submitInsuranceClaimAction.pending, (state) => {
        state.submitInsuranceClaimPending = true
      })
      .addCase(submitInsuranceClaimAction.fulfilled, (state, action) => {
        state.submitInsuranceClaimPending = false
      })
      .addCase(submitInsuranceClaimAction.rejected, (state, action) => {
        state.submitInsuranceClaimPending = false
      })
  }
})

export default InsuranceReducer.reducer
