import { createSlice } from '@reduxjs/toolkit'

import {
  getPayersAction,
  getProviderPayersAction,
  addProviderPayerAction,
  deleteProviderPayerAction
} from './payerAction'

const PayerReducer = createSlice({
  name: 'payer',
  initialState: {
    getProviderPayerListPending: false,
    getPayerListPending: false,
    addProviderPayerPending: false,
    deleteProviderPayerPending: false,
    getProviderPayerList: {
      result: [],
      offset: 0,
      limit: 10,
      count: 0
    },
    getPayerList: [],

    error: null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      // ** Get all activities
      .addCase(getProviderPayersAction.pending, (state) => {
        state.getProviderPayerListPending = true
      })
      .addCase(getProviderPayersAction.fulfilled, (state, action) => {
        state.getProviderPayerListPending = false
        state.getProviderPayerList = action.payload
      })
      .addCase(getProviderPayersAction.rejected, (state, action) => {
        state.getProviderPayerListPending = false
        state.getProviderPayerList = {
          result: [],
          offset: 0,
          limit: 10,
          count: 0
        }
        state.error = action.payload
      })
      .addCase(getPayersAction.pending, (state) => {
        state.getPayerListPending = true
      })
      .addCase(getPayersAction.fulfilled, (state, action) => {
        state.getPayerListPending = false
        state.getPayerList = action.payload?.payers
      })
      .addCase(getPayersAction.rejected, (state, action) => {
        state.getPayerListPending = false
      })
      .addCase(addProviderPayerAction.pending, (state) => {
        state.addProviderPayerPending = true
      })
      .addCase(addProviderPayerAction.fulfilled, (state, action) => {
        state.addProviderPayerPending = false
      })
      .addCase(addProviderPayerAction.rejected, (state, action) => {
        state.addProviderPayerPending = false
      })
      .addCase(deleteProviderPayerAction.pending, (state) => {
        state.deleteProviderPayerPending = true
      })
      .addCase(deleteProviderPayerAction.fulfilled, (state, action) => {
        state.deleteProviderPayerPending = false
      })
      .addCase(deleteProviderPayerAction.rejected, (state, action) => {
        state.deleteProviderPayerPending = false
      })
  }
})

export default PayerReducer.reducer
