// export const MAIN_URL = 'http://192.168.1.7:8000' // Testing URL

// export const MAIN_URL = 'https://backend-dot-konav2-dev.wl.r.appspot.com' // development URL

// export const MAIN_URL = 'https://konav2.wl.r.appspot.com' // Live URL

// export const MAIN_URL = 'https://backend-dot-konav2.wl.r.appspot.com' // Staging URLs
export const SOCKET_MAIN_URL = process.env.REACT_APP_SOCKET_SERVER
export const MAIN_URL = process.env.REACT_APP_SERVER // Staging URL
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY
