import _, { create } from 'lodash'
import moment from 'moment-timezone'
import * as Yup from 'yup'

import { jwtDecode as jwt_decode } from 'jwt-decode'

export const namePrivacyHandler = (name) => {
  // Split the full name into separate parts
  const parts = name.split(' ')

  // Extract the first character of each part
  const initials = parts.map((part) => part.charAt(0)).join('')
  return initials
}
export const isJSON = (str) => {
  try {
    JSON.parse(str)
    return true
  } catch (e) {
    return false
  }
}

export const isObjEmpty = (obj) => Object.keys(obj).length === 0
export const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) {
    return phoneNumber
  }
  phoneNumber = phoneNumber.replace(/\D/g, '')
  const formattedRegex = /^\(\d{3}\) \d{3}-\d{4}$/
  if (formattedRegex.test(phoneNumber)) {
    return phoneNumber // If already formatted, return as is
  }

  // Format the number into (XXX) YYY-ZZZZ
  const formattedNumber = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(3, 6)}-${phoneNumber.substring(6)}`

  return formattedNumber
}
export const kFormatter = (num) => {
  if (num > 999) {
    return `${(num / 1000).toFixed(1)}k`
  } else return num
}
export const calculateYearDifference = (date1) => {
  const dob = new Date(date1)
  const today = new Date()
  let age = today.getFullYear() - dob.getFullYear()

  const birthdayThisYear = new Date(
    today.getFullYear(),
    dob.getMonth(),
    dob.getDate()
  )

  age -= today < birthdayThisYear ? 1 : 0

  return age
}

export const htmlToString = (html) => html.replace(/<\/?[^>]+(>|$)/g, '')
export const getTime = (data, format = 'LT') =>
  data && moment(data).format(format)

const isToday = (date) => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

export const formatDate = (
  value,
  formatting = { month: 'short', day: 'numeric', year: 'numeric' }
) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}
export const isUserLoggedIn = () => localStorage.getItem('userData')
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))
export const getAccessToken = () =>
  jwt_decode(localStorage.getItem('accessToken'))
export const getHomeRouteForLoggedInUser = (role) => {
  let DefaultRoute
  const { permissions } = getAccessToken()

  if (role) {
    if (
      permissions?.appointment_with_booking_access ||
      permissions?.other_location_appointment ||
      permissions?.booking
    ) {
      DefaultRoute = '/calendar'
      return DefaultRoute
    } else {
      DefaultRoute = '/activity'
      return DefaultRoute
    }
  }
  return '/login'
}

export const selectThemeColors = (theme) => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const getUserFullName = (user, defaultName = 'Other') => {
  if (!user) {
    return defaultName
  }
  const fullName = `${user.firstName || ''} ${user.middleName || ''} ${
    user.lastName || ''
  }`
    .trim()
    .replace(/ +(?= )/g, '')
  const fullNameRs = _.isEmpty(fullName) ? defaultName : fullName

  return fullNameRs
}

// American Phone Number
export const PhoneUS = (value, previousValue) => {
  if (!value) return value
  const currentValue = value.replace(/[^\d]/g, '')
  const cvLength = currentValue.length
  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue
    if (cvLength < 7) {
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`
    }
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`
  }
}

export const getChangedData = (oldData, newData) => {
  const changedData = []

  newData.forEach((newObj) => {
    const oldObj = oldData.find((o) => o.id === newObj.id)

    if (!oldObj) {
      changedData.push({ id: newObj.id, services: newObj.services })
    } else {
      const changedServices = []

      newObj.services.forEach((newService) => {
        const oldService = oldObj.services.find(
          (s) => s.service === newService.service
        )

        if (!oldService || oldService.fees !== newService.fees) {
          changedServices.push(newService)
        }
      })

      if (changedServices.length > 0) {
        changedData.push({ id: newObj.id, services: changedServices })
      }
    }
  })

  return changedData
}

// for patch request
export const getModifiedValues = (values, initialValues) => {
  const modifiedValues = {}

  if (values) {
    Object.entries(values).forEach((entry) => {
      const key = entry[0]
      const value = entry[1]

      if (value !== initialValues[key]) {
        modifiedValues[key] = value
      }
    })
  }

  return modifiedValues
}

export const getSuperModifiedValues = (values, initialValues) => {
  const modifiedValues = {}

  if (values) {
    Object.entries(values).forEach((entry) => {
      const key = entry[0]
      const value = entry[1]
      const initialValue = initialValues[key]

      if (key === 'appointment_services') {
        if (JSON.stringify(value) !== JSON.stringify(initialValue)) {
          modifiedValues[key] = value
        }
      } else if (Array.isArray(value) && Array.isArray(initialValue)) {
        if (JSON.stringify(value) !== JSON.stringify(initialValue)) {
          modifiedValues[key] = value
        }
      } else if (
        typeof value === 'object' &&
        value !== null &&
        typeof initialValue === 'object' &&
        initialValue !== null
      ) {
        if (JSON.stringify(value) !== JSON.stringify(initialValue)) {
          modifiedValues[key] = value
        }
      } else if (value !== initialValue) {
        modifiedValues[key] = value
      }
    })
  }

  return modifiedValues
}

export const dateUnix = (date) => {
  return moment(`${date}`).tz('America/Los_Angeles').unix()
}

export const dateUS = (date) => {
  return moment.unix(`${date}`).tz('America/Los_Angeles').format('MM/DD/YYYY')
}

export const dateFormatted = (date, format) => {
  return moment.unix(`${date}`).format(format)
}

export const timeConvertToPmAm = (time) => {
  time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
    time
  ]

  if (time.length > 1) {
    time = time.slice(1)
    time.splice(3, 1)
    time[5] = +time[0] < 12 ? ' AM' : ' PM'
    time[0] = +time[0] % 12 || 12
  }
  return time.join('')
}

export const timeFormat = (timeField) => {
  return Yup.string().matches(
    /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/,
    `${timeField} must be in the format HH:mm:ss`
  )
}

export const endTimeGreaterThanStartTime = (startTimeField, endTimeField) => {
  return Yup.string().test(
    endTimeField,
    `${endTimeField} must be greater than ${startTimeField}`,
    // eslint-disable-next-line no-unused-vars
    function (value) {
      const { [startTimeField]: startTime, [endTimeField]: endTime } =
        // eslint-disable-next-line no-invalid-this
        this.parent
      return (
        !startTime || !endTime || startTime < endTime || startTime === endTime
      )
    }
  )
}

export const checkForEmptyValues = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key]
      if (value === '' || value === null || value === undefined) {
        return new Error(`Value for key '${key}' is empty, null or undefined.`)
      }
    }
  }
  return null // return null if there are no empty values
}

export const cleanNullKeyPair = (obj) => {
  for (const propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === ''
    ) {
      delete obj[propName]
    }
  }
  return obj
}

export const isTodayBooking = (bookingDate, bookingStartTime, bookingEndTime) =>
  moment(bookingDate)
    .add(bookingStartTime?.split(':')[0], 'hours')
    .add(bookingEndTime?.split(':')[1], 'minutes')
    .unix() <
  moment().unix() + 86400

export const checkTimeForCancelBooking = (
  bookingStartDate,
  bookingStartTime,
  bookingCreationDate,
  currentTimeServer
) => {
  const now = moment.unix(currentTimeServer?.current_time)

  const bookingDate = moment.unix(bookingStartDate, 'MM/DD/YYYY')
  const creationDate = moment.unix(bookingCreationDate)
  const bookingMoment = `${bookingDate.clone().format('MM/DD/YYYY')}  ${bookingStartTime}`
  const combinedMoment = moment(bookingMoment, 'MM/DD/YYYY HH:mm:ss')
  const secondsDifference = combinedMoment.diff(now, 'seconds')
  const creationToBookingDifference = now.diff(creationDate, 'hours')

  if (secondsDifference > 24 * 60 * 60 || creationToBookingDifference < 1) {
    return true
  } else {
    return false
  }
}

export const makeType10FormsData = (content, keysOrder) => {
  const finalContent = { ...content }
  Object.entries(finalContent)?.forEach((item, index) => {
    if (typeof item[1] === 'string') {
      if (item[1]?.split('____')?.length === 1) {
        finalContent[item[0]] = item[1]
      } else {
        const temp = [finalContent[item[0]]]
        for (let i = 0; i < item[1]?.split('____')?.length - 1; i++) {
          temp?.push('')
        }
        finalContent[item[0]] = temp
      }
    }
  })
  return finalContent
}
export const roundToNearest15Minutes = (date) => {
  const minutes = date.getMinutes()
  const roundedMinutes = Math.round(minutes / 15) * 15
  date.setMinutes(roundedMinutes)
  date.setSeconds(0) // Reset seconds to 0
  return date
}
export const checkOnlyBookingPermissionsTrue = (permissions) => {
  const data = {
    booking: permissions?.booking,

    appointment_with_booking_access:
      permissions?.appointment_with_booking_access,
    other_location_appointment: permissions?.other_location_appointment
  }
  // Check if value1 is true
  if (!data.booking) {
    return false
  }

  // Check if all other values are false or falsy
  for (const key in data) {
    if (key !== 'booking' && data[key]) {
      return false // If any value other than value1 is true, return false
    }
  }

  return true
}
export const checkOnlyEhrPermissionsTrue = (permissions) => {
  const data = {
    booking: permissions?.booking,

    appointment_with_booking_access:
      permissions?.appointment_with_booking_access,
    other_location_appointment: permissions?.other_location_appointment
  }
  // Check if value1 is true
  if (!data.other_location_appointment) {
    return false
  }

  // Check if all other values are false or falsy
  for (const key in data) {
    if (key !== 'other_location_appointment' && data[key]) {
      return false // If any value other than value1 is true, return false
    }
  }

  return true
}
