import { createAsyncThunk } from '@reduxjs/toolkit'
import useJwt from '@src/auth/jwt/useJwt'

import { ToastContent } from '@src/components/toast'
import { Check, X } from 'react-feather'
import toast from 'react-hot-toast'
export const getCreatedClaimDataAction = createAsyncThunk(
  'submit-claim/getCreatedClaimDataAction',
  async ({ providerId, claimId }, { rejectWithValue }) => {
    try {
      let response
      response = await useJwt.getProviderDetails(providerId)
      const provider = response.data
      response = await useJwt.retriveInsuranceClaim(claimId)
      const claim = response.data
      response = await useJwt.getClient(claim.client_services.client.id)
      const client = response.data

      response = await useJwt.getValidationRulesForClaim(
        client.client_insurance[0].insurance_payer,
        claim.claim_type
      )
      const configurations = response.data
      response = await useJwt.getBillingInfoAction(providerId)
      const billingInfo = response.data
      return {
        provider,
        claim,
        client,
        configurations,
        billingInfo
      }
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Claim'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const getAllUnbilledInvoicesAction = createAsyncThunk(
  'unbilled-Invoices/getAllUnbilledInvoices',
  async (
    { offset, limit, startDate, endDate, time, search },
    { rejectWithValue }
  ) => {
    try {
      const response = await useJwt.getAllUnbilledInvoices({
        offset,
        limit,
        startDate,
        endDate,
        time,
        search
      })

      return response.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Invoices'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const submitInsuranceClaimAction = createAsyncThunk(
  'submit-insurance-claim/submitInsuranceClaimAction',
  async ({ data, claimId, callback }, { rejectWithValue }) => {
    try {
      const response = await useJwt.submitInsuranceClaim(data, claimId)
      if (callback) {
        callback()
      }
      return response.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Submitting Claim'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.msg}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const createInsuranceClaimAction = createAsyncThunk(
  'unbilled-Invoices/createInsuranceClaim',
  async ({ data, type, callback }, { dispatch, rejectWithValue }) => {
    try {
      const response = await useJwt.createInsuranceClaim(data, type)
      toast((t) => (
        <ToastContent
          t={t}
          name={'Success'}
          icon={<Check size={14} />}
          color="success"
          msg={'Claim created successfully'}
        />
      ))
      if (callback) {
        callback()
      }
      return response.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error creating claim'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
export const getAllInsuranceClaimsAction = createAsyncThunk(
  'insuranceClaims/getAllInsuranceClaims',
  async ({ offset, limit, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await useJwt.getAllInsuranceClaims({
        offset,
        limit,
        startDate,
        endDate
      })

      return response.data
    } catch (err) {
      toast((t) => (
        <ToastContent
          t={t}
          name={'Error Fetching Invoices'}
          icon={<X size={14} />}
          color="danger"
          msg={err?.response?.data?.message}
        />
      ))
      return rejectWithValue(err?.response?.data)
    }
  }
)
// ** Handling Pagination
export const handlePageChange = createAsyncThunk(
  'insuranceClaims/handlePageChange',
  async ({ offset, limit, startDate, endDate }, { dispatch }) => {
    dispatch(
      getAllInsuranceClaimsAction({
        offset,
        limit,

        startDate,
        endDate,

        callback: () => {}
      })
    )
  }
)

// ** Handling Limit
export const handleLimitChange = createAsyncThunk(
  'insuranceClaims/handleLimitChange',
  async (
    { oldLimit, newLimit, search, startDate, endDate, time },
    { dispatch }
  ) => {
    if (newLimit !== oldLimit) {
      dispatch(
        getAllInsuranceClaimsAction({
          offset: 0,
          limit: newLimit,
          time,
          search,
          startDate,
          endDate
        })
      )
    }
  }
)
